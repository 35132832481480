import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { verifyJson } from "utils/validateAemJson";
import { globalPaths } from "@RHCommerceDev/aem/globalModels";
import { globalModels } from "@RHCommerceDev/aem/globalModels";
import { getAemEnv } from "@RHCommerceDev/aem/getAemEnv";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useEnv } from "hooks/useEnv";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import yn from "yn";

const PRIMARY = "primary";
const SECONDARY = "secondary";
export const processModelResponse = data => {
  const escapeXml = unsafe => {
    return unsafe.replace(/(&lt;|&gt;|&amp;|&apos;|&quot;)/g, function (c) {
      switch (c) {
        case "&lt;":
          return "<";
        case "&gt;":
          return ">";
        case "&amp;":
          return "&";
        case "&apos;":
          return "'";
        case "&quot;":
          return '"';
      }
    });
  };
  let pageContent = verifyJson(data, "getAemModel")
    ? JSON.parse(data.getAemModel.model)
    : {};

  Object.keys(data ?? {}).forEach(
    key => {
      if (verifyJson(data, key) && pageContent) {
        pageContent[key] = JSON.parse(data[key]?.model || "");
      }
    },
    { pageContent, data }
  );
  const recursion = obj => {
    for (let i in obj) {
      if (typeof obj[i] == "object") {
        recursion(obj[i]);
      } else {
        typeof obj[i] === "string" && escapeXml(obj[i]);
      }
    }
  };
  recursion(pageContent);
  return pageContent;
};

export const globalQueryVariables = modelPath => {
  const aemEnv = getAemEnv();
  return {
    aemModelInput: {
      modelPath
    },
    ...Object.fromEntries(
      Object.entries(globalModels).map(([key, value]) => {
        return [
          key,
          {
            modelPath: globalPaths[key],
            aemEnv
          }
        ];
      })
    )
  };
};

export const queryGetAemModelGlobal = gql`
  query GetAemModel(
    $aemModelInput: AemModelInput!
    ${Object.keys(globalModels || {}).map(key => {
      return `$${key}: AemModelInput!`;
    })}
  ) {
    ${Object.keys(globalModels || {}).map(key => {
      return `${key}: getAemModel(aemModelInput: $${key}) {
        model
      }`;
    })}

    getAemModel(aemModelInput: $aemModelInput) {
      model
    }
  }
`;

const isFeatureHeroHp = () => {
  const cookies = useIsoCookies();
  const featureHpHeroCookie = cookies["FEATURE_HP_HERO"];

  const env = useEnv();
  const featureHpHero = featureHpHeroCookie
    ? yn(featureHpHeroCookie)
    : yn(env.FEATURE_HP_HERO);
  return featureHpHero;
};

const getExperimentCookie = () => {
  const cookies = useIsoCookies();

  const hp_hero = cookies["hp_hero"];

  let valHpHero;
  if (processEnvServer) {
    /* 
    why here is the inverse 
      because we get the request ie with hp_hero=primary
      on the server we need to use the opposite 
      wehn we return this request the set-cookie header says hp_hero=secondary
      this way the graphQL calls are the same on the server and front end and we can use the cache one
    */
    valHpHero = hp_hero === PRIMARY ? SECONDARY : PRIMARY;
  } else {
    valHpHero = hp_hero ? hp_hero : PRIMARY;
  }

  return valHpHero;
};

const buildExtraArguments = () => {
  const featureHeroHp = !!isFeatureHeroHp();
  const heroCookie = getExperimentCookie();
  return {
    featureHeroHp,
    hp_hero: heroCookie ?? ""
  };
};

export const globalAemModel = variables => {
  const queryGetAemModelGlobal = gql`
  query GetAemModel(
    $aemModelInput: AemModelInput!
    ${Object.keys(variables?.globalPaths || {}).map(key => {
      return `$${key}: AemModelInput!`;
    })}
  ) {
    ${Object.keys(variables?.globalPaths || {}).map(key => {
      return `${key}: getAemModel(aemModelInput: $${key}) {
        model
      }`;
    })}

    getAemModel(aemModelInput: $aemModelInput) {
      model
    }
  }
`;

  const sanitizePath = variables.modelPath.replace(/\/\/+/g, "/");

  const extraArguments = buildExtraArguments();
  const extraVariables = Object.fromEntries(
    Object.entries(variables?.globalPaths).map(
      ([key, value]: [string, string]) => {
        return [key, { modelPath: value?.replace(/\/\/+/g, "/") }];
      }
    )
  );

  return useQuery(queryGetAemModelGlobal, {
    variables: {
      aemModelInput: {
        modelPath: sanitizePath,
        ...extraArguments
      },
      ...extraVariables
    },
    client: variables.graphqlClient,
    skip: variables?.skip || false
  });
};

export const aemModel = variables => {
  const aemEnv = getAemEnv();
  const sanitizePath = variables.modelPath.replace(/\/\/+/g, "/");

  return useQuery(queryGetAemModelGlobal, {
    variables: {
      aemModelInput: {
        modelPath: sanitizePath,
        aemEnv,
        ...buildExtraArguments()
      }
    },
    client: variables.graphqlClient
  });
};

export const queryGetAemModel = gql`
  query GetAemModel($aemModelInput: AemModelInput!) {
    getAemModel(aemModelInput: $aemModelInput) {
      model
    }
  }
`;
